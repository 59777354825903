.media {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	width: 600px;

	.header {
		width: 100%;
		display: flex;
		padding: 83px 32px 0px 32px;
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;

		.page-title {
			font-family: "SF Pro Text";
			font-size: 46px;
			font-style: normal;
			font-weight: 700;
			line-height: 53px;
			color: #000;
		}

		.media-title {
			display: flex;
			flex-direction: row;
			color: #000;
			font-family: "SF Pro Text";
			font-size: 24px;
			font-style: normal;
			font-weight: 500;
			line-height: 33px;

			a {
				margin-left: 5px;
			}
		}
	}

	.body {
		display: flex;
		padding: 32px;
		flex-direction: column;
		align-items: flex-start;
		gap: 32px;
		flex: 1 0 0;
		align-self: stretch;

		.tabs {
			display: flex;
			align-items: flex-start;
			align-self: stretch;
		}

		.tab-content {
			width: 536px;

			.title {
				color: #000;
				font-family: "SF Pro Text";
				font-size: 24px;
				font-style: normal;
				font-weight: 400;
				line-height: 33px;
			}
		}
	}

	.footer {
		width: 100%;
		padding: 22px 32px 22px 32px;
		gap: 10px;

		.buttons {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			gap: 16px;

			button {
				padding: 12px 16px 12px 16px;
				min-width: 85px;
				height: 48px;
				border-radius: 4px;
				font-weight: 500;
				font-size: 16px;
				line-height: 24px;
				cursor: pointer;
			}
		}
	}
}
